<template>
  <section
    v-if="hasContent()"
    :id="carousel.ref"
    class="py-0 lg:px-28 lg:py-28"
    :style="bgColor ? `background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, ${bgColor} 100%);` : ''"
  >
    <hgroup v-if="carousel.title" class="text-center">
      <h2 class="leading-3xl text-5xl font-bold lg:text-7xl lg:leading-5xl" v-html="carousel.title" />
      <h3 v-if="carousel.subtitle" class="mt-6 text-2xl" v-html="carousel.subtitle" />
    </hgroup>
    <div class="container mx-auto flex flex-col sm:px-6 2xl:max-w-8xl">
      <Carousel
        :value="carousel.items"
        :numVisible="carousel.numVisible ? carousel.numVisible : 3"
        :numScroll="1"
        :responsiveOptions="responsiveOptions"
        :showIndicators="carousel.showIndicators"
        :showNavigators="carousel.showNavigators"
        :circular="carousel.circular"
      >
        <template #item="slotProps">
          <template v-if="carousel.itemStyle === 'basic'">
            <Basic :content="slotProps" />
          </template>
          <template v-if="carousel.itemStyle === 'shopping'">
            <ShoppingInfo :shopping="slotProps" @campaign-info="campaignInfo" />
          </template>
          <template v-if="carousel.itemStyle === 'image'">
            <Image :image="slotProps" />
          </template>
          <template v-if="carousel.itemStyle === 'quote'">
            <Quote :content="slotProps" />
          </template>
        </template>
      </Carousel>
      <WhiteLabelStorekeeper
        v-if="carousel.itemStyle === 'shopping'"
        :selectedWl="selectedShopping"
        :wlName="wlName"
        :slug="props.slug"
      />
      <Button v-if="carousel.cta" class="mx-auto" :width="240" :href="carousel.cta.url">
        {{ carousel.cta.label }}
      </Button>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, watchEffect } from "vue";

import Button from "@/components/Buttons/Button/v2/Button.vue";

import WhiteLabelStorekeeper from "../Dialogs/WhiteLabelStorekeeper.vue";
import Basic from "./CarouselItems/Basic.vue";
import Image from "./CarouselItems/Image.vue";
import Quote from "./CarouselItems/Quote.vue";
import ShoppingInfo from "./CarouselItems/ShoppingInfo.vue";

const emit = defineEmits(["startAd"]);

const props = defineProps({
  carousel: {
    type: Object,
    default: () => ({}),
  },
  wlName: {
    type: String,
    default: "",
  },
  bgColor: {
    type: String,
    default: "",
  },
  slug: {
    type: String,
    default: "",
  },
});

const hasContent = () => {
  return props.carousel != null ? Object.keys(props.carousel).length != 0 : false;
};
const hasCta = () => {
  return props.carousel.cta != null ? Object.keys(props.carousel.cta).length != 0 : false;
};
const bgColor = computed(() => props.bgColor);
const responsiveOptions = ref([
  {
    breakpoint: "1199px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "767px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
    numScroll: 1,
  },
]);

const selectedShopping = ref({});

const campaignInfo = (s) => {
  selectedShopping.value = s;
};
</script>

<style lang="scss" scoped>
:deep(.p-carousel-content .p-carousel-prev:enabled),
:deep(.p-carousel-content .p-carousel-next:enabled) {
  background-color: #f4f4f5;
}
.custom-img {
  @media screen and (min-width: 1024px) {
    img {
      max-width: 287px;
    }
  }
}
</style>
