<template>
  <div class="flex flex-col gap-6 mt-6 items-center">
    <figure class="carousel-image w-full gap-6 rounded-xs overflow-hidden mx-6 relative pointer-events-none">
      <img
        :src="image.data.image.url"
        :title="image.data.title"
        class="w-full h-full block absolute top-0 left-0 bottom-0 right-0 object-cover"
        :alt="image.data.title"
        loading="lazy"
        width="100%"
        height="100%"
      />
    </figure>
    <p class="text-center text-xl">
      {{ image.data.title }}
    </p>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";

const props = defineProps({
  image: {
    type: Object,
    required: true,
  },
});
const image = ref("");
watchEffect(() => {
  image.value = props.image;
});
</script>

<style lang="scss" scoped>
.carousel-image {
  max-width: 335px;
  min-height: 245px;
}
</style>
