<template>
  <div class="carousel-item flex flex-col items-center lg:p-10 bg-white rounded-md my-10 mx-8 max-lg:w-auto shrink-0">
    <figure class="shrink-0 rounded-6xl overflow-hidden custom-img w-full mx-auto flex">
      <img
        :src="content.data.image.url"
        :alt="content.data.image.alt"
        class="block mx-auto"
        loading="lazy"
        width="350px"
        height="350px"
      />
    </figure>
    <div class="w-full flex flex-col mt-4">
      <strong class="text-4xl text-center">
        {{ content.data.title }}
      </strong>
      <p class="mt-1 text-xl px-5" v-html="content.data.description" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const content = computed(() => props.content);
</script>
