<template>
  <div
    class="carousel-item flex flex-col lg:flex-row items-center p-0 lg:p-10 bg-white rounded-md my-10 lg:mx-8 max-lg:w-auto shrink-0"
  >
    <figure class="shrink-0 rounded-full overflow-hidden w-[150px]">
      <img :src="content.data.image.url" :alt="content.data.image.alt" loading="lazy" width="150px" height="150px" />
    </figure>
    <div class="w-full flex flex-col mt-4 px-5">
      <p class="text-2xl border-bl" v-html="content.data.review" />
      <p class="mt-1 text-xl font-bold" v-html="content.data.name" />
      <p class="text-xl font-bold" v-html="content.data.company" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

import useIsMobile from "@/hooks/useIsMobile";
const { isMobile } = useIsMobile();

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const content = computed(() => props.content);
</script>
