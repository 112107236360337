<template>
  <div class="carousel-item mx-auto my-10 flex shrink-0 flex-col rounded-md bg-white p-10 max-lg:w-auto">
    <figure class="flex h-[269px] w-full shrink-0 overflow-hidden rounded-sm">
      <img
        :src="shopping.data?.image.url"
        :alt="`Foto do ${shopping.data?.title} em ${shopping.data?.location}`"
        class="h-full w-full object-cover object-center"
        loading="lazy"
      />
    </figure>
    <div class="flex flex-col items-start">
      <strong class="mt-10 text-xl text-primary-default">
        {{ shopping.data?.title }}
      </strong>
      <ul class="text-xl">
        <li v-if="shopping.data?.location" class="mt-5">
          <span class="uil uil-map-marker mr-3" />{{ shopping.data?.location }}, {{ shopping.data?.state }}
        </li>
        <li v-if="shopping.data?.screens" class="mt-5">
          <span class="uil uil-desktop mr-3" />{{ shopping.data?.screens }} telas
        </li>
        <li v-if="shopping.data?.lojista" class="mt-5 whitespace-nowrap font-semibold">
          <span class="uil uil-usd-circle mr-3" />a partir de {{ $currency(shopping.data?.lojista) }} para lojistas
          <p class="pl-9 text-base font-light text-primary-default">
            Valor sem desconto a partir de {{ $currency(shopping.data?.value) }}
          </p>
        </li>
      </ul>
      <a
        v-if="shopping.data?.cta.show"
        :href="shopping.data?.cta.url"
        class="p-button mt-6"
        @click="campaignInfo(shopping.data)"
        >{{ shopping.data?.cta.label }}
        <i v-if="shopping.data?.cta.icon" class="uil ml-3 !font-normal !text-white" :class="shopping.data?.cta.icon" />
        <i v-else class="uil uil-calender ml-3 !font-normal !text-white" />
      </a>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, watch } from "vue";

const props = defineProps({
  shopping: {
    type: Object,
    required: true,
  },
});
const shopping = reactive(props.shopping);
const emit = defineEmits(["campaignInfo"]);

const campaignInfo = (s) => {
  let campaign = Object.assign({}, s);
  emit("campaignInfo", campaign);
};

const storekeeper = ref(false);
const duration = ref("14");

const normalizeName = (n) => {
  return n.toLowerCase().replaceAll(" ", "-").trim();
};
</script>
<style lang="scss" scoped>
.carousel-item {
  max-width: 351px;
}
.p-carousel-item:has(+ .p-carousel-item:last-child) {
  &:first-child {
    .carousel-item {
      margin-left: auto;
    }
  }
}
</style>
